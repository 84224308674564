import React from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { Link, graphql } from 'gatsby'

import Layout, { extractData, THEMES } from '../components/layout'
import { Page, Routing, SSRAware, posterWidthAndHeight, Row, Column } from '../components/utils'
import { Movie, Cinema, CinemaId, Model, CinemasMap } from '../model/model'

import Section from '../components/section';
import createStore from '../model/create-store'
import { Provider, connect } from 'react-redux';
import ConnectedUpdater from '../components/updater';
import RImage from '../components/image';
import { Dispatch } from 'redux';
import { Action } from '../model/actions';
import { Tracking, isNotNull } from '../model/utils';
import Option from '../components/option';

const Card = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  align-items: center;
  @media (min-width: 700px) {
    padding: 5px 10px;
    max-width: 300px;
  }
`
const Poster = styled(RImage)`
  display: flex;
  ${posterWidthAndHeight(120)}
  object-fit: cover;
  @media (min-width: 700px) {
    ${posterWidthAndHeight(130)}
  }
`
const MovieName = styled.h3`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-transform: uppercase;
  text-align: center;
  font-size: 1rem;
  margin: 0.5rem;
  font-weight: 300;
  @media (min-width: 700px) {
    line-height: 1.3rem;
  }
`

interface MovieCardProps {
  movie: Movie
  shows: string[]
}

const MovieCard: React.SFC<MovieCardProps & SSRAware> = ({isSSR, movie, shows}) => (
  <Card>
    <Poster mode={isSSR ? 'stub' : 'lazy'} image={movie.poster} alt={`Poster film ${movie.title}`}
      sizes={[{viewport: 700, size: 120}]}
      maxSize={150} />
    <MovieName>
      {shows.length === 0
      ? null
      : shows[0] === '*'
        ? '*'
        : <Row>
            {shows.map (s =>
              <Option key={s} style={{margin: '0 5px 5px 5px'}}>{s}</Option>
            )}
          </Row>
      }
      {movie.title}
    </MovieName>
  </Card>
)

interface MoviesSection {
  name: string
  cards: MovieCardProps[]
}
interface StaticProps {
  mainCinemaId: CinemaId
  cinemas: Cinema[]
}
interface DynamicProps {
  sections: MoviesSection[]
}
type PageProps = StaticProps & DynamicProps

const Group = styled(Row)`
flex-wrap: wrap;
justify-content: center;
margin-bottom: 2rem;
@media (min-width: 700px) {
  justify-content: flex-start;
}
`
const GroupName = styled.div`
color: ${props => props.theme.colors.highlight};
display: flex;
flex-grow: 0;
align-items: center;
justify-content: center;
text-align: center;
width: 100%;
margin-bottom: 1rem;
@media (min-width: 700px) {
  justify-content: flex-start;
  text-align: left;
  width: 25%;
}
`
const GroupMovie = styled(Column)`
width: 100%;
@media (min-width: 700px) {
  width: 25%;
}
`

const PageTitle = styled.h1`
text-align: center;
color: ${props => props.theme.colors.highlight};
`

const PageContent = styled.div`
text-align: center;
& p {
  font-size: 1.1rem;
  line-height: 1.7rem;
}
& li {
  list-style: none;
  font-weight: bold;
}
`

const TwoEuroPage: React.SFC<PageProps & SSRAware> = ({isSSR, cinemas, mainCinemaId, sections}) => {
  return (
    <Layout showBackButton={false} cinemas={cinemas} title="Cinema a 2€">
      <Page>
        <Section>
          <PageTitle>Cinema a 2 €uro {new Date().getFullYear()}</PageTitle>
          { mainCinemaId === 'mds'
          ? <PageContent>
              <p>
                Anche quest'estate torna "Cinema a 2 €uro" <br/>
                la nostra esclusiva rassegna con i migliori film dell'anno a soli €2!
              </p>
              <ul>
                <li>Tutti i giorni alle 18:00 e 21:00</li>
              </ul>
            </PageContent>
          : <PageContent>
              <p>
                Per la prima volta anche al Cinema Odeon 6 arriva il "Cinema a 2 €uro"  <br/>
                La rassegna dei migliori film dell'anno a soli €2!
              </p>
            </PageContent>
          }

        { sections.map(section =>
          <Group key={section.name}>
            <GroupName dangerouslySetInnerHTML={{__html: section.name}}></GroupName>
            { section.cards.map ((card, idx) =>
              <GroupMovie key={idx}>
                { card.movie.slug === 'film-a-sorpresa'
                ? <MovieCard isSSR={isSSR} movie={card.movie} shows={card.shows}/>
                : <Link to={Routing.toMoviePage(card.movie)}
                  onClick={() => {Tracking.trackNavigation('film-2-euro/main', card.movie.title)}}>
                    <MovieCard isSSR={isSSR} movie={card.movie} shows={card.shows}/>
                  </Link>
              }
              </GroupMovie>
            )}
          </Group>
        )}
        </Section>
      </Page>
    </Layout>
  )
}

const MOVIES_2EURO: CinemasMap<{name: string[], movieInfos: string[]}[]> = {
  mds: [
    {
      name:['LUNEDI 8 LUGLIO',
      'MARTEDI 9 LUGLIO',
      'MERCOLEDI 10 LUGLIO',
      'GIOVEDI 11 LUGLIO',],
      movieInfos: ['ce-ancora-domani', 'kung-fu-panda-4', 'ghostbusters-minaccia-glaciale']
    },
    {
      name:['VENERDI 12 LUGLIO',
      'SABATO 13 LUGLIO',
      'DOMENICA 14 LUGLIO',
      'LUNEDI 15 LUGLIO',],
      movieInfos: ['challengers', 'emma-e-il-giaguaro-nero', 'aquaman-e-il-regno-perduto']
    },
    {
      name:['MARTEDI 16 LUGLIO',
      'MERCOLEDI 17 LUGLIO',
      'GIOVEDI 18 LUGLIO',
      'VENERDI 19 LUGLIO',],
      movieInfos: ['un-mondo-a-parte', 'wonka', 'dune-parte-due']
    },
    { 
      name:['SABATO 20 LUGLIO',
      'DOMENICA 21 LUGLIO',
      'LUNEDI 22 LUGLIO',
      'MARTEDI 23 LUGLIO',],
      movieInfos: ['povere-creature', 'haikyu-the-dumpster-battle', 'godzilla-e-kong-il-nuovo-impero']
    },
    {
      name:['MERCOLEDI 24 LUGLIO',
      'GIOVEDI 25 LUGLIO',
      'VENERDI 26 LUGLIO',
      'SABATO 27 LUGLIO',],
      movieInfos: ['napoleon', 'wish', 'tutti-tranne-te']
    },
    {
      name:['DOMENICA 28 LUGLIO',
      'LUNEDI 29 LUGLIO',
      'MARTEDI 30 LUGLIO',
      'MERCOLEDI 31 LUGLIO',],
      movieInfos: ['ce-ancora-domani', 'prendi-il-volo', 'the-fall-guy']
    },
    {
      name:['GIOVEDI 1 AGOSTO',
      'VENERDI 2 AGOSTO',
      'SABATO 3 AGOSTO',
      'DOMENICA 4 AGOSTO',],
      movieInfos: ['past-lives', 'il-ragazzo-e-lairone', 'bob-marley-one-love']
    },
    {
      name:['LUNEDI 5 AGOSTO',
      'MARTEDI 6 AGOSTO',
      'MERCOLEDI 7 AGOSTO',
      'GIOVEDI 8 AGOSTO',],
      movieInfos: ['io-capitano', 'garfield-una-missione-gustosa', 'il-regno-del-pianeta-delle-scimmie']
    },
    {
      name:['VENERDI 9 AGOSTO',
      'SABATO 10 AGOSTO',
      'DOMENICA 11 AGOSTO',
      'LUNEDI 12 AGOSTO',],
      movieInfos: ['un-mondo-a-parte', 'trolls-3-tutti-insieme', 'bad-boys-ride-or-die']
    },
    {
      name:['MARTEDI 13 AGOSTO',
      'MERCOLEDI 14 AGOSTO',
      'GIOVEDI 15 AGOSTO',
      'VENERDI 16 AGOSTO',],
      movieInfos: ['anatomia-di-una-caduta', 'if-gli-amici-immaginari', 'back-to-black']
    },
    {
      name:['SABATO 17 AGOSTO',
      'DOMENICA 18 AGOSTO',
      'LUNEDI 19 AGOSTO',
      'MARTEDI 20 AGOSTO',],
      movieInfos: ['perfect-days', 'film-a-sorpresa', 'furiosa-a-mad-max-saga']
    },
  ],
  odeon6: [
  ]
}

const mapModelToProps = (model: Model, ownProps: StaticProps): DynamicProps & SSRAware => {
  if (model.page.id !== 'events') {
    throw new Error('Unexpected state when computing Events props: ' + model.page.id)
  }
  return {
    isSSR: model.isSSR,
    sections: MOVIES_2EURO[ownProps.mainCinemaId].map(s => ({
      name: s.name.join('<br/>'),
      cards: s.movieInfos
        .map(slugCode => {
          const [slug, ...showTokens] = slugCode.split(' ')
          const movie = model.extraMovies.find(m => slug === m.slug)
          return movie == null
          ? null
          : {
              movie: movie,
              shows: showTokens
            }
        })
        .filter(isNotNull)
    }))
  }
}

const mapDispatchToIntents = (dispatch: Dispatch<Action>) => ({
})

const ConnectedPage = connect(
  mapModelToProps,
  mapDispatchToIntents
)(TwoEuroPage as any)

const WrappedPage: React.SFC<any> = ( query ) => {
  const { mainCinemaId, cinemas } = extractData(query)
  const store = createStore('events', cinemas, mainCinemaId, false)

  return (
    <ThemeProvider theme={THEMES[mainCinemaId]}>
      <Provider store={store}>
        <>
          <ConnectedUpdater/>
          <ConnectedPage cinemas={cinemas} mainCinemaId={mainCinemaId} />
        </>
      </Provider>
    </ThemeProvider>
  )
}

export default WrappedPage

export const query = graphql`
query TwoEurosPageQuery {
  ...alldata
}
`
