import React from 'react'
import styled from 'styled-components'

const Option = styled.div`
  width: fit-content;
  height: fit-content;
  padding: 5px;
  border: 1px solid white;
  @media(min-width: 700) {
    padding: 10px;
  }
`

export default Option